import * as React from 'react';

let listeners: {
  id: number;
  cb: (event: KeyboardEvent) => void;
}[] = [];
let execListener: any = undefined;
let escapePropagate = true;

const isEscape = (event: any) =>
  event && (event.key === 'Escape' || event.keyCode === 27);

const useEscape = (
  callback: (event: KeyboardEvent) => void,
  source: EventTarget = window
) => {
  React.useEffect(() => {
    if (!execListener) {
      execListener = (event: KeyboardEvent) => {
        for (let i = listeners.length - 1; escapePropagate && i >= 0; i--) {
          listeners[i].cb(event);
        }
        escapePropagate = true;
      };
      source.addEventListener('keydown', execListener);
    }

    const id = Date.now();
    const cb = ((evt: KeyboardEvent) => {
      if (isEscape(evt)) {
        callback(evt);
      }
    }) as EventListener;

    listeners.push({ id, cb });

    return () => {
      listeners = listeners.filter((l) => l.id !== id);

      if (listeners.length === 0) {
        source.removeEventListener('keydown', execListener);
        execListener = undefined;
      }
    };
  });
};

export const stopEscapePropagation = (
  event: KeyboardEvent | React.KeyboardEvent<any>
) => {
  if (isEscape(event)) {
    const e = (event as any).nativeEvent ? (event as any).nativeEvent : event;
    e.stopImmediatePropagation();
    escapePropagate = false;
  }
};

export default useEscape;
