import styled from '../../styledComponents';

const FullHeight = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;

export default FullHeight;
