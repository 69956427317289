import * as React from 'react';

const useDelayMessage = (timeout: number, messageDelay: number) => {
  const delayMessages = [
    'Almost done',
    'This seems to take a bit longer today',
    'Maybe your internet sucks',
  ];
  const [initial, setInitial] = React.useState(true);
  const [messageIndex, setMessageIndex] = React.useState(0);
  React.useEffect(() => {
    const to = setTimeout(() => {
      const index =
        messageIndex < delayMessages.length - 1 ? messageIndex + 1 : 0;
      if (initial) {
        setInitial(false);
      } else {
        setMessageIndex(index);
      }
    }, initial ? timeout : messageDelay);
    return () => {
      clearTimeout(to);
    };
  });
  return delayMessages[messageIndex];
};

export default useDelayMessage;
