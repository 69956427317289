import { FlattenSimpleInterpolation, Keyframes } from 'styled-components';
import { css, keyframes } from './styledComponents';

export interface ThemeInterface {
  importFonts: FlattenSimpleInterpolation;

  fontFamily: string;
  fontWeight: string;
  fontSize: string;
  lineHeight: string;

  fontFamilyHeader: string;
  fontSizeHeaderH1: string;
  fontSizeHeaderH2: string;
  fontSizeHeaderH3: string;
  fontSizeHeaderH4: string;
  fontSizeHeaderH5: string;
  fontSizeHeaderH6: string;
  fontWeightHeader: string;
  fontSizeHeader: string;
  lineHeightHeader: string;

  color: string;
  primaryColor: string;
  // For input borders etc.
  enabledColor: string;
  informationColor: string;
  secondaryColor: string;
  warningColor: string;
  disabledColor: string;

  borderRadius: number;

  mainTaskHeight: number;
  subTaskHeight: number;

  transitionSpeed: string;

  colors: {
    STATUS_BG: {
      DRAFT: string;
      SENDING: string;
      SENT: string;
      ACCEPTED: string;
      DECLINED: string;
    };
    STATUS_BG_HOVER: {
      DRAFT: string;
      SENDING: string;
      SENT: string;
      ACCEPTED: string;
      DECLINED: string;
    };
  };

  animations: {
    FADE_IN: Keyframes;
    DRAW_ANIMATION: Keyframes;
  };
}

const importFonts = css`
  @import url('https://fonts.googleapis.com/css?family=Montserrat|B612+Mono|ZCOOL+XiaoWei');
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,900&display=swap');
`;
const theme: ThemeInterface = {
  importFonts,
  primaryColor: '#A7C24E', // Green // '#D5429F', // Pink - later to be changed globally
  secondaryColor: '#383b3c', // Dark grey
  informationColor: '#969798', // Mid grey
  disabledColor: '#e1e3e4', // Light grey
  warningColor: '#d43b2f', // Dark red

  enabledColor: '#ccc', // ? Not in design
  color: '#333', // ? Not in design
  borderRadius: 3, // ? Not distinct in design

  fontFamily: '"Rotobo", sans-serif',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '1.5em',

  fontFamilyHeader: '',
  fontSizeHeaderH1: '1.5714em',
  fontSizeHeaderH2: '1.4285em',
  fontSizeHeaderH3: '1.2857em',
  fontSizeHeaderH4: '1.125em',
  fontSizeHeaderH5: '1em',
  fontSizeHeaderH6: '.8em',
  fontWeightHeader: '',
  fontSizeHeader: '',
  lineHeightHeader: '',

  mainTaskHeight: 45,
  subTaskHeight: 35,

  transitionSpeed: '0.2s',

  colors: {
    STATUS_BG: {
      DRAFT: 'transparent',
      SENDING: 'rgb(57, 122, 187)',
      SENT: 'rgb(57, 122, 187)',
      ACCEPTED: 'rgb(57, 171, 54)',
      DECLINED: 'rgb(229, 54, 42)',
    },
    STATUS_BG_HOVER: {
      DRAFT: 'rgba(255, 255, 255, 0.5)',
      SENDING: 'rgba(57, 122, 187, 0.8)',
      SENT: 'rgba(57, 122, 187, 0.8)',
      ACCEPTED: 'rgba(57, 171, 54, 0.8)',
      DECLINED: 'rgba(229, 54, 42, 0.8)',
    },
  },

  animations: {
    FADE_IN: keyframes`
      from {
        opacity: 0.0;
      }
    `,
    DRAW_ANIMATION: keyframes`
  to {
    stroke-dashoffset: 0;
  }
`,
  },
};

const defaultColors = [
  'f1cb00',
  'f1a802',
  'ed8403',
  'e75907',
  'eb4600',
  'dc1225',
  'c8277c',
  'bd3d8f',
  '9f4895',
  '894493',
  '664092',
  '444194',
  '2d4697',
  '2e56a7',
  '316ead',
  '2789ca',
  '44aeca',
  '56bec0',
  '46b694',
  '439555',
  '4b9d33',
  '58ab2d',
  '6cb532',
  '90c035',
  'a0cc00',
  'c2d803',
];

export const getColorForString = (value: string) => {
  if (isNaN(Number(value))) {
    // Color for strings
    value = value
      .replace('ä', 'a')
      .replace('ü', 'u')
      .replace('ö', 'o')
      .replace('ß', 's')
      .replace('ä', 'a')
      .toLocaleLowerCase();

    const numberInAplphabet = value.charCodeAt(0) - 97;

    if (numberInAplphabet < defaultColors.length) {
      return '#' + defaultColors[numberInAplphabet];
    }
  }

  // Color for numbers and fallback
  return theme.primaryColor;
};

/**
 * Convert hex color string to rgb(a) color string
 * @param hex Hex color as string
 * @param opacity Float value between 1.0 and 0.0
 * @return RGBA color string
 */
export const hexToRgba = (hex: string, opacity: number = 1.0) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    return `rgba(0,0,0,0)`;
  }
  return `rgba(${parseInt(result[1], 16)}, ${parseInt(
    result[2],
    16
  )}, ${parseInt(result[3], 16)}, ${opacity})`;
};

export default theme;
