import * as React from 'react';

interface Props {
  onFocus?: (ev: FocusEvent) => void;
  onBlur?: (ev: FocusEvent) => void;
}

const useWindowFocus = ({ onBlur, onFocus }: Props) => {
  const [isFocused, setIsFocused] = React.useState(false);

  const focusListener = (ev: FocusEvent) => {
    setIsFocused(true);
    onFocus && onFocus(ev);
  };

  const blurListener = (ev: FocusEvent) => {
    setIsFocused(false);
    onBlur && onBlur(ev);
  };

  React.useEffect(() => {
    window.addEventListener('focus', focusListener);
    window.addEventListener('blur', blurListener);
    return () => {
      window.removeEventListener('focus', focusListener);
      window.removeEventListener('blur', blurListener);
    };
  });

  return isFocused;
};

export default useWindowFocus;
