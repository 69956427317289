import { Router } from '@reach/router';
import gql from 'graphql-tag';
import * as React from 'react';
import { useQuery } from 'react-apollo-hooks';
import pkg from '../../_version.json';
import FullHeight from '../../components/FullHeight';
import Loader from '../../components/Loader';
import GlobalStyle from '../../globalStyles';
import useTokenRefresher from '../../hooks/useTokenRefresher';
import { ConfiguredStore } from '../../store/configureStore';
import styled, { ThemeProvider } from '../../styledComponents';
import theme from '../../theme';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Sortly, { ContextProvider, ItemRendererProps } from 'react-sortly';
import * as gqlService from '../../generated/graphql';
import { useLocalStorage } from 'react-use';
import { useEffect, useState } from 'react';
import Modal from '../../components/Modal';
import { ApolloClient, NormalizedCacheObject } from 'apollo-boost';
import useWindowFocus from '../../hooks/useWindowFocus';

const GET_SERVER_VERSION = gql`
  query ServerVersion {
    version
  }
`;

const ViewportWrapper = styled.div`
  width: 100vw;
  height: 100vh;
`;

const loadComponent = (
  Component:
    | React.LazyExoticComponent<any>
    | ((store: ConfiguredStore) => React.LazyExoticComponent<any>)
) => (props: { path?: string; default?: boolean; store?: ConfiguredStore }) => {
  const { store } = props;
  let Comp: any;
  if (store) {
    Comp = Component(props.store!);
  } else {
    Comp = Component;
  }
  return (
    <React.Suspense
      fallback={
        <ViewportWrapper>
          <Loader inline={false} />
        </ViewportWrapper>
      }
    >
      <Comp {...props} />
    </React.Suspense>
  );
};

// These components will be loaded first when the path matches (code-splitting)
const TasksAsync = React.lazy(() => import('../Tasks'));
const BillingAsync = React.lazy(() => import('../Billing'));
const DocumentsAsync = React.lazy(() => import('../Documents'));
const OfferWebViewAsync = React.lazy(() => import('../OfferWebView'));
const CustomerAsync = React.lazy(() => import('../Customer'));
const LoginAsync = React.lazy(() => import('../Login'));
const NotFoundAsync = React.lazy(() => import('../NotFound'));

const TasksLoader = loadComponent(TasksAsync);
const BillingLoader = loadComponent(BillingAsync);
const CustomerLoader = loadComponent(CustomerAsync);
const DocumentsLoader = loadComponent(DocumentsAsync);
const OfferWebViewLoader = loadComponent(OfferWebViewAsync);
const LoginLoader = loadComponent(LoginAsync);
const NotFoundLoader = loadComponent(NotFoundAsync);

interface Props {
  store: ConfiguredStore;
  client: ApolloClient<NormalizedCacheObject>;
}

const LargeSpan = styled.span`
  width: 100px;
  display: inline-block;
`;

const ShortSpan = styled.span`
  width: 50px;
  display: inline-block;
`;

const CenterShortSpan = styled.span`
  width: 50px;
  display: inline-block;
  text-align: center;
`;

export const BASE_URLS = {
  LOGIN: '/login',
  TASKS: '/tasks',
  BILLING: '/billing',
  CUSTOMERS: '/customers',
  INVOICE: '/documents/invoice',
  COST_ESTIMATE: '/documents/costEstimate',
  OFFER: '/documents/offer',
  OFFER_WEBVIEW: '/offerwebview',
  RECURRING_INVOICES: '/documents/recurringInvoices',
};

const App = (props: Props) => {
  useWindowFocus({ onFocus: () => refetch() });
  useTokenRefresher();

  const { data, loading, refetch } = useQuery<{ version: string }>(
    GET_SERVER_VERSION
  );
  const currentVersion = {
    ui: pkg.version,
    backend: data?.version || '0.0',
  };
  const [versionLogged, setVersionLogged] = React.useState(false);
  const [version, setVersion] = useLocalStorage('version', {
    ui: '',
    backend: '',
  });
  const showUpdateNotification =
    !!data &&
    version.ui !== '' &&
    (version.ui !== pkg.version || version.backend !== data.version);

  const updateLoggedVersion = React.useCallback(
    (reloadPage: boolean = false) => {
      setVersion({ ui: pkg.version, backend: data?.version || '' });
      if (reloadPage) {
        window.location.reload();
      }
    },
    [pkg.version, data?.version, version, showUpdateNotification]
  );

  gqlService.useBackendUpdatedSubscription({
    onSubscriptionData: () => {
      refetch();
    },
  });

  if (!versionLogged && !loading && data) {
    console.log(
      `🚀 Server version %cv${data.version}`,
      'color: green; font-weight: bold'
    );
    console.log(
      `🚀 UI version %cv${pkg.version}`,
      'color: green; font-weight: bold'
    );
    updateLoggedVersion();
    setVersionLogged(true);
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <ContextProvider>
        <ThemeProvider theme={theme}>
          <FullHeight>
            <Modal
              header={`Software Update 🚀`}
              isOpen={showUpdateNotification}
              onPrimary={() => updateLoggedVersion(true)}
              onClose={() => updateLoggedVersion()}
            >
              <div>
                <p>
                  Die Software wurde aktualisiert. Es sind nun folgenden
                  Versionen aktiv:
                </p>
                <p>
                  <LargeSpan>Plan UI:</LargeSpan>{' '}
                  {version.ui !== currentVersion.ui && (
                    <>
                      <ShortSpan>{version.ui}</ShortSpan>
                      <CenterShortSpan>{` 👉 `}</CenterShortSpan>
                    </>
                  )}
                  {currentVersion.ui}
                </p>
                <p>
                  <LargeSpan>Plan Backend:</LargeSpan>{' '}
                  {version.backend !== currentVersion.backend && (
                    <>
                      <ShortSpan>{version.backend}</ShortSpan>
                      <CenterShortSpan>{` 👉 `}</CenterShortSpan>
                    </>
                  )}
                  {currentVersion.backend}
                </p>
                <p>
                  Durch Bestätigen mit Ok wird die Seite neu geladen, damit alle
                  Änderungen aktiv werden.
                </p>
              </div>
            </Modal>
            <Router>
              <TasksLoader path={`/`} />
              <TasksLoader path={`${BASE_URLS.TASKS}/*`} />
              <CustomerLoader path={`${BASE_URLS.CUSTOMERS}/:action`} />
              <DocumentsLoader path={`${BASE_URLS.OFFER}`} />
              <DocumentsLoader path={`${BASE_URLS.OFFER}/*`} />
              <DocumentsLoader path={`${BASE_URLS.COST_ESTIMATE}`} />
              <DocumentsLoader path={`${BASE_URLS.COST_ESTIMATE}/*`} />
              <DocumentsLoader path={`${BASE_URLS.INVOICE}`} />
              <DocumentsLoader path={`${BASE_URLS.INVOICE}/*`} />
              <DocumentsLoader path={`${BASE_URLS.RECURRING_INVOICES}`} />
              <DocumentsLoader path={`${BASE_URLS.RECURRING_INVOICES}/*`} />
              <OfferWebViewLoader path={`${BASE_URLS.OFFER_WEBVIEW}/*`} />
              <BillingLoader path={`${BASE_URLS.BILLING}/*`} />
              <LoginLoader path={BASE_URLS.LOGIN} />
              <NotFoundLoader default={true} />
            </Router>
            <GlobalStyle />
          </FullHeight>
        </ThemeProvider>
      </ContextProvider>
    </DndProvider>
  );
};

export default App;
