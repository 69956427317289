import styled from '../../styledComponents';

export const Backdrop = styled('div')<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  pointer-events: ${props => (props.isOpen ? 'inherit' : 'none')};
  opacity: ${props => (props.isOpen ? '1' : '0')};
  transition: opacity ${props => props.theme.transitionSpeed} ease-out;
  overflow: auto;
  z-index: 1000000;
  perspective: 1000px;
`;

export const Body = styled('div')<{ isOpen: boolean }>`
  flex-shrink: 0;
  margin: auto;
  box-sizing: border-box;
  max-width: 700px;
  width: 50%;
  background: #fff;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  position: relative;
  transform: ${props =>
    props.isOpen ? 'none' : 'rotate3d(1, 0, 0, 40deg) translateY(-300px)'};
  transition: transform ${props => props.theme.transitionSpeed} ease-out;
`;
export const Header = styled.div`
  padding: 1rem;
  background: rgb(45, 47, 49);
  color: #fff;
`;
export const Content = styled.div`
  padding: 1rem;
`;
export const Footer = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #ccc;
`;
