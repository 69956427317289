import styled, { css } from '../../styledComponents';

interface ButtonProps {
  disabled?: boolean;
  primary?: boolean;
  danger?: boolean;
  fullWidth?: boolean;
}

const Button = styled.button<ButtonProps>`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  border-radius: 3px;
  padding: 10px 15px;
  background: ${(props) =>
    props.primary
      ? props.theme.primaryColor
      : props.danger
      ? props.theme.warningColor
      : '#fff'};
  color: ${(props) => (props.primary || props.danger ? '#fff' : '#333')};
  border-color: ${(props) =>
    props.primary
      ? props.theme.primaryColor
      : props.danger
      ? props.theme.warningColor
      : ' #ccc'};
  border-width: 1px;
  border-style: solid;
  font-size: 1em;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  margin: 0 5px;
  transition: all ${(props) => props.theme.transitionSpeed} ease-out;
  ${(props) =>
    props.fullWidth === true &&
    css`
      width: 100%;
    `}

  &:hover {
    background-color: ${(props) => props.theme.secondaryColor};
    border-color: ${(props) => props.theme.secondaryColor};
    color: #fff;
  }
`;

export default Button;
