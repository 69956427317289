import { combineReducers, Reducer } from "redux";
// import voiceReducer from '../containers/Voices/reducer';

const rootReducer = (state: any = {}) => state;

// Add reducers here that are always required
// e.g. for authentication
const createReducer = (
  asyncReducers: {
    [reducerName: string]: Reducer<any, any>;
  } = {}
) =>
  combineReducers({
    rootReducer,
    // voices: voiceReducer,
    ...asyncReducers
  });

export default createReducer;
