import { createGlobalStyle } from './styledComponents';

const GlobalStyle = createGlobalStyle`
  ${props => props.theme.importFonts}
  body, html {
    margin: 0;
    font-family: ${props => props.theme.fontFamily};
    font-size: ${props => props.theme.fontSize};
    font-weight: ${props => props.theme.fontWeight};
    color: ${props => props.theme.color};
    line-height: ${props => props.theme.lineHeight};
    height: 100%;
    background: rgb(250, 250, 250);
  }
  #root {
    height: 100%;
  }
  #portals {}
  h1, h2, h3, h4, h5, h6 {
    font-family: ${props => props.theme.fontFamilyHeader};
    font-weight: ${props => props.theme.fontWeightHeader};
    padding: 0;
    margin: 0.5em 0;
    line-height: 1.25em;
  }
  h1 { font-size: ${props => props.theme.fontSizeHeaderH1}; }
  h2 { font-size: ${props => props.theme.fontSizeHeaderH2}; }
  h3 { font-size: ${props => props.theme.fontSizeHeaderH3}; }
  h4 { font-size: ${props => props.theme.fontSizeHeaderH4}; }
  h5 { font-size: ${props => props.theme.fontSizeHeaderH5}; }
  h6 { font-size: ${props => props.theme.fontSizeHeaderH6}; }
`;

export default GlobalStyle;
