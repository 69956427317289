import * as React from 'react';
import * as ReactDOM from 'react-dom';
import useEscape from '../../hooks/useEscape';
import Button from '../Button';
import { Backdrop, Body, Content, Footer, Header } from './Components';

interface Props {
  isOpen: boolean;
  disabled?: boolean;
  onlyPrimaryBtn?: boolean;
  onClose: () => void;
  closeOnPrimary?: boolean;
  primaryBtnLabel?: string;
  onPrimary?: () => void;
  header: any | any[];
  children: JSX.Element;
  footer?: JSX.Element;
}

const Modal = (props: Props) => {
  const {
    isOpen,
    header,
    footer,
    children,
    onClose,
    onPrimary,
    disabled,
    onlyPrimaryBtn,
    primaryBtnLabel,
    closeOnPrimary = true,
  } = props;
  const [el] = React.useState(document.createElement('div'));
  // Add the element on mount
  React.useEffect(() => {
    document.body.appendChild(el);
    // Remove it on unmount
    return () => {
      el.remove();
    };
  }, []);
  // Allow Escape to close
  useEscape((event) => {
    if (isOpen) {
      event.stopImmediatePropagation();
      onClose();
    }
  }, document);

  const [mouseIsOver, setMouseIsOver] = React.useState(false);

  return ReactDOM.createPortal(
    <Backdrop
      isOpen={isOpen}
      onClick={(e) => {
        if (mouseIsOver) {
          setMouseIsOver(false);
          return;
        }
        onClose();
      }}
    >
      <Body
        isOpen={isOpen}
        onMouseDown={(event) => {
          setMouseIsOver(true);
        }}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          event.nativeEvent.stopImmediatePropagation();
          setMouseIsOver(false);
        }}
      >
        <Header>{header}</Header>
        <Content>{children}</Content>
        <Footer>
          {footer}
          {!(onlyPrimaryBtn === true) && (
            <Button
              onClick={() => {
                onClose();
              }}
            >
              Abbrechen
            </Button>
          )}
          {(onPrimary || onlyPrimaryBtn) && (
            <Button
              disabled={disabled}
              primary={true}
              onClick={() => {
                if (onPrimary) {
                  onPrimary();
                }
                if (closeOnPrimary) {
                  onClose();
                }
              }}
            >
              {primaryBtnLabel || `Ok`}
            </Button>
          )}
        </Footer>
      </Body>
    </Backdrop>,
    el
  );
};

export default Modal;
