import { createStore, compose, applyMiddleware, Store, Reducer } from "redux";
import { createLogicMiddleware } from "redux-logic";
import createReducer from "./createReducer";
import rootLogic from "./rootLogic";

const dependencies = {};

export const logicMiddleware = createLogicMiddleware(rootLogic, dependencies);

const devTools =
  ((<any>window).__REDUX_DEVTOOLS_EXTENSION__ &&
    (<any>window).__REDUX_DEVTOOLS_EXTENSION__()) ||
  compose;

export interface ConfiguredStore extends Store {
  asyncReducers: { [reducerName: string]: Reducer<any, any> };
}

const configureStore = () => {
  const store = <ConfiguredStore>(<any>createStore(
    createReducer(),
    compose(
      applyMiddleware(logicMiddleware /*, ...other middlewares */),
      devTools
    )
  ));
  store.asyncReducers = {};
  return store;
};

export default configureStore;
