import * as React from 'react';
import useTimeout from 'react-use/lib/useTimeout';
import { ReactComponent as SVGLoader } from '../../images/oval.svg';
import styled, { keyframes } from '../../styledComponents';
import useDelayMessage from './useDelayMessage';

const Rotation = keyframes`
 0% {
   transform: rotate(0deg);
 }
 100% {
   transform: rotate(360deg);
 }
`;
const Wrapper = styled.div<{ inline: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.inline ? 'auto' : '100%')};
  height: ${(props) => (props.inline ? 'auto' : '100%')};
  svg {
    animation-name: ${Rotation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`;
const Text = styled.p`
  color: ${(props) => props.theme.color};
  margin-top: 5px;
`;

interface Props {
  inline?: boolean;
  stroke?: string;
  showText?: boolean; // Whether to show text after a certain amount of time
  initialDelay?: number; // Time to wait before actually showing the loader
  initialMessageDelay?: number; // Time to wait before showing some entertaining text
  nextMessageDelay?: number; // Time between switching the messages
  style?: React.CSSProperties;
  children?: JSX.Element | string;
}

const Loader = (props: Props) => {
  const {
    inline = false,
    stroke = '#333',
    showText = true,
    initialDelay = 200,
    initialMessageDelay = 3000,
    nextMessageDelay = 5000,
    style,
    children,
  } = props;

  // Before showing anything we wait for <initialDelay> milliseconds
  // to avoid flashing for fast loading components
  const [delayIsReady] = useTimeout(initialDelay);
  // When the component takes longer we show the initial loading message
  const [textDelayIsReady] = useTimeout(initialDelay + initialMessageDelay);
  // If the component takes longer than <initialDelay + initialMessageDelay> milliseconds
  // we show an alternating delay message that changes every <nextMessageDelay> milliseconds
  const delayMessage = useDelayMessage(
    initialDelay + initialMessageDelay,
    nextMessageDelay
  );

  if (!delayIsReady()) {
    return <>{props.children}</> || null;
  }

  const doShowText = !inline && showText && textDelayIsReady();
  return (
    <Wrapper inline={inline} style={style}>
      <SVGLoader stroke={stroke} />
      {doShowText && <Text>{delayMessage}</Text>}
    </Wrapper>
  );
};

export default Loader;
