import { navigate } from '@reach/router';
import gql from 'graphql-tag';
import Cookie from 'js-cookie';
import {
  clearTimeout as clearLongTimeout,
  setTimeout as setLongTimeout,
} from 'long-timeout';
import * as React from 'react';
import { useMutation } from 'react-apollo-hooks';
import useUpdate from 'react-use/lib/useUpdate';
import { BASE_URLS } from '../containers/App/App';

const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshAuthentication(where: { refreshToken: $refreshToken }) {
      message
      token
      expiry
    }
  }
`;

const useTokenRefresher = () => {
  const forceUpdate = useUpdate();

  // Muration to update the refresh token
  const [refresh] = useMutation<{
    refreshAuthentication: {
      token?: string;
      expiry?: number;
      message?: string;
    };
  }>(REFRESH_TOKEN, {
    update: (_, { data }) => {
      if (!data) {
        console.log('No data received for refresh token request...');
        return;
      }
      const { refreshAuthentication } = data;
      const { message, token, expiry } = refreshAuthentication;
      if (message) {
        console.log('Refresh message', message);
      }
      if (token && expiry) {
        console.log('Auth token refreshed...');
        // localStorage.setItem('auth_token', token);
        // localStorage.setItem('auth_token_expiry', `${expiry}`);
        Cookie.set('auth_token', token, {
          expires: new Date(expiry),
        });
        Cookie.set('auth_token_expiry', expiry.toString(), {
          expires: new Date(expiry),
        });
        forceUpdate();
      }
    },
  });

  // When the refreshToken expires navigate to login
  React.useEffect(() => {
    // const refreshTokenExpiryStr = localStorage.getItem(
    //   'refresh_token_expiry'
    // ) as string;
    const refreshTokenExpiryStr = Cookie.get('refresh_token_expiry') || '';
    const refreshTokenExpiryNum: number = parseInt(refreshTokenExpiryStr, 10);
    const refreshTokenExpiry: number | null = isNaN(refreshTokenExpiryNum)
      ? null
      : refreshTokenExpiryNum;

    if (typeof refreshTokenExpiry !== 'number') {
      return;
    }

    const logout = () => navigate(BASE_URLS.LOGIN);
    const remainingMs = refreshTokenExpiry - Date.now();
    if (remainingMs <= 0) {
      console.log('Got expired refresh token...');
      localStorage.setItem('last_opened_url', window.location.href);
      logout();
      return;
    }
    const handle = setLongTimeout(() => {
      console.log('Refresh token expired...');
      localStorage.setItem('last_opened_url', window.location.href);
      logout();
    }, remainingMs);
    return () => clearLongTimeout(handle);
  });

  // When the token expires refresh it
  React.useEffect(() => {
    // const expiryStr = localStorage.getItem('auth_token_expiry') as string;
    const expiryStr = Cookie.get('auth_token_expiry') || '';
    const expiryNum: number = parseInt(expiryStr, 10);
    // typeof NaN is also 'number', therefore we assure it's null if not parsable as number
    const expiry: number | null = isNaN(expiryNum) ? null : expiryNum;
    if (typeof expiry !== 'number') {
      return;
    }
    // const refreshToken = localStorage.getItem('refresh_token');
    const refreshToken = Cookie.get('refresh_token');
    if (!refreshToken) {
      console.log('No refresh token found...');
      return;
    }
    const remainingMs = expiry - Date.now();
    // console.log('Remaining ms', remainingMs);
    const handle = setLongTimeout(() => {
      refresh({ variables: { refreshToken } });
    }, remainingMs);
    return () => clearLongTimeout(handle);
  });
};

export default useTokenRefresher;
